<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>
  <h3 >Change Password</h3>
      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" type="password" label="Enter Old Password" v-model="formData['oldPassword']" v-validate="'required'" name="oldPassword" />
          <vs-input class="w-full mt-4"  @input="checkPassword"   type="password" label="Enter New Password" v-model="formData['newPassword']" v-validate="'required'" name="newPassword" />
          <span class="text-danger text-sm" v-if="show_password_err_msg">Password Must Be Combination Of 8 Characters Must Includes One Number,Uppercase,Special Character..!!</span>
          <vs-input class="w-full mt-4" type="password" label="Re-type Password" v-model="formData['retypePassword']" v-validate="'required'" name="retypePassword" />
        </div>
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="changePasswordJWT" :disabled="!validateForm">Save Changes</vs-button>
            <vs-button class="ml-4 mt-2" color="warning" @click="clear_data">Reset</vs-button>
          </div>
        </div>
      </div>

    </vx-card>
</template>

<script>
// import moduleEmployee          from '@/store/employee/moduleEmployee.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  data () {
    return {
      promptName    :'Edit Profile',
      formData      :{},
      show_password_err_msg: false,
       valid_password:''
    }
  },
  components: {
    vSelect,
    flatPickr
  },
  computed: {
    employee ()     { 
      if(!this.$route.params.id){
        return {"cognito_id":this.$store.state.AppActiveUser.cognito_id};
      }else if('my_profile' == this.$route.params.id){
        return this.$store.state.AppActiveUser
      }else{
        return this.$store.getters['employee/getEmployee'](this.$route.params.id)
      }
    },
    validateForm () {
      return !this.errors.any() && this.formData.oldPassword !== '' && this.valid_password == 1 &&  this.formData.retypePassword !== "" &&  this.formData.retypePassword && this.formData.oldPassword
    }
  },
  methods: {
     checkPassword() {
      this.password_length = this.formData['newPassword'].length;
			const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
			}
			
      this.contains_number = /\d/.test(this.formData['newPassword']);
      this.contains_uppercase = /[A-Z]/.test(this.formData['newPassword']);
			this.contains_special_character = format.test(this.formData['newPassword']);
      
      if (this.contains_eight_characters === true && this.contains_special_character === true && this.contains_uppercase === true && this.contains_number === true) {
						this.valid_password = true;		
            this.show_password_err_msg = false	
      } else {
            this.show_password_err_msg = true	
            this.valid_password = false;
      }
    },
    clear_data(){
      this.formData = Object.assign({}, this.employee)
    },
   changePasswordJWT () {
      if(this.formData.newPassword != this.formData.retypePassword){
          this.$vs.notify({
                title: 'Alert',
                text: 'Please enter new Password and retype Password must be same..!!',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
          return
      }
      this.$vs.loading()
      this.$store.dispatch('auth/changePasswordJWT', Object.assign({}, this.formData))
        .then(() => { this.$vs.loading.close() })
        .catch(error => {
          if(error.message=="Incorrect username or password."){
              error.message = "Incorrect old password"
          }else{
            error.message = error.message
          }
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Alert',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    }
  },   
}
</script>
 